import React, { useEffect, useState } from "react";
import "./CategoryCars.css";
import { getCarsByBodyType } from "../../services/apis";
import CarCard from "../../Components/CarCard/CarCard";
import Loader from "../../Components/Loader/Loader";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import EmptyState from "../../Components/EmptyState/EmptyState";
import MessageBox from "../../Components/MessageBox/MessageBox";
import { goToPageWithQuery } from "../../utils/helpers";

function CategoryCars() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [cars, setCars] = useState({
    Sedan: [],
    Hatchback: [],
    SUV: [],
    Crossover: [],
    Coupe: [],
    Convertible: [],
  });

  const fetchCars = async (bodyType) => {
    try {
      setLoading(true);
      const res = await getCarsByBodyType(bodyType);
      setLoading(false);
      if (res && res.length > 0) {
        setCars((prevCars) => ({
          ...prevCars,
          [bodyType]: res,
        }));
      }
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    ["Sedan", "Hatchback", "SUV", "Crossover", "Coupe", "Convertible"].forEach(
      fetchCars
    );
  }, []);

  return (
    <div style={{ backgroundColor: "#fffaf3" }}>
      <div className="container">
        <h2 className="font-md text-center">CARS BY CATEGORY</h2>
        <Tabs>
          <TabList>
            {[
              "Sedan",
              "Hatchback",
              "SUV",
              "Crossover",
              "Coupe",
              "Convertible",
            ].map((category) => (
              <Tab key={category}>
                <span className="font-sm">{category}</span>
              </Tab>
            ))}
          </TabList>

          {[
            "Sedan",
            "Hatchback",
            "SUV",
            "Crossover",
            "Coupe",
            "Convertible",
          ].map((category) => (
            <TabPanel key={category}>
              <div className="cards-container">
                {loading ? (
                  <Loader />
                ) : error ? (
                  <>Error: {error}</>
                ) : cars[category].length === 0 ? (
                  <>
                    <MessageBox title={"No cars found!"} type={"not-found"} />
                  </>
                ) : (
                  cars[category].map((car) => (
                    <div key={car.id}>
                      <CarCard car={car} />
                    </div>
                  ))
                )}
              </div>
            </TabPanel>
          ))}
        </Tabs>
        <div className="text-center">
          <button
            className="button-transparent mt-4 mb-5"
            onClick={() => goToPageWithQuery()}
            aria-label="Search for used cars"
          >
            View All
          </button>
        </div>
      </div>
    </div>
  );
}

export default CategoryCars;
