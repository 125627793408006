export const carBodyTypes = [
  { index: 1, text: "Hatchback", value: "hatchback" },
  { index: 2, text: "Sedan", value: "sedan" },
  { index: 3, text: "SUV", value: "suv" },
  { index: 4, text: "Crossover", value: "crossover" },
  { index: 5, text: "Coupe", value: "coupe" },
  { index: 6, text: "Convertible", value: "convertible" },
  { index: 7, text: "Wagon", value: "wagon" },
  { index: 8, text: "Minivan", value: "minivan" },
  { index: 9, text: "Pickup Truck", value: "pickup_truck" },
  { index: 10, text: "Roadster", value: "roadster" },
];
