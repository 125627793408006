import React, { useEffect, useState } from "react";
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";
import {
  addDealerToChatList,
  getCarDetails,
  getProfileById,
} from "../../services/apis";
import FavoriteIcon from "@mui/icons-material/Favorite";
import useAuth from "../../hooks/useAuth";
import Loader from "../../Components/Loader/Loader";
import "./DetailsScreen.css";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { auth } from "../../config/Firebase";
import { Helmet } from "react-helmet-async";
import BasicModal from "../../Components/BasicModal/BasicModal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function DetailsScreen() {
  const [car, setCar] = useState(null);
  const [loading, setLoading] = useState(false);
  const [mainImage, setMainImage] = useState("");
  const { id: carId } = useParams();
  const { isLoggedIn } = useAuth();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const user = auth.currentUser;

  const [dealerDetails, setDealerDetails] = useState(null);
  console.log("car-----------", car ? car : "no car");
  const getDealerDetails = async (profileId) => {
    const resp = await getProfileById(profileId);

    if (resp) {
      return resp;
    }
  };

  const fetchCar = async () => {
    setLoading(true);
    let res = await getCarDetails(carId);

    if (res && res.userId) {
      // getDealerDetails(res.userId)
      getDealerDetails("d1buNTSxJDWizETSE9BObYMFqFP2"); // Development only
    }
    setLoading(false);
    setCar(res);
    setMainImage(res?.images[0]);
  };

  useEffect(() => {
    fetchCar();
  }, []);

  const addToChatList = async () => {
    // const dealer = await getDealerDetails("d1buNTSxJDWizETSE9BObYMFqFP2");
    const dealer = await getDealerDetails(car?.userId);
    console.log("DEALER---------", dealer ? dealer : "no dealer");
    console.log("user-------", user);
    if (user && dealer && user.uid) {
      console.log("hhhhhhhhhhhh");
      const obj = {
        ...dealer,
        myId: user.uid,
      };
      await addDealerToChatList(obj);
      return true;
    }
  };

  const goToChat = async () => {
    if (!isLoggedIn) {
      // alert("Please log in to chat with the dealer.");
      handleOpen();
    } else {
      await addToChatList();
      window.location.href = `/chat`;
    }
  };

  return (
    <div className="details-screen">
      <Helmet>
        <title>used cars for Sale - Best Deals</title>
        <meta
          name="description"
          content={`Buy ${car?.name} at an affordable price. Check details now!`}
        />
        <meta
          name="keywords"
          content="used cars, buy used cars, car sales, second-hand cars"
        />
        <meta property="og:title" content={` ${car?.name} for Sale`} />
        <meta
          property="og:description"
          content={`Get the best deal on  ${car?.name} Check it out now!`}
        />
      </Helmet>
      <Breadcrumb />
      <div className="container">
        {loading ? (
          <div className="loading">
            <Loader />
          </div>
        ) : (
          <>
            <div className="row">
              <div className="col-md-6">
                <div className="image-wrapper">
                  <img
                    src={mainImage}
                    className="main-image w-100"
                    alt="Car"
                    loading="lazy"
                  />
                </div>
                <div className="thumbnail-container bg-white p-4">
                  {car?.images?.map((img, index) => (
                    <div
                      key={index}
                      className={`thumbnail-frame ${
                        mainImage === img ? "active-thumbnail" : ""
                      }`}
                      onClick={() => setMainImage(img)}
                    >
                      <img
                        src={img}
                        className="thumbnail-img"
                        alt={`Thumbnail ${index + 1}`}
                        loading="lazy"
                      />
                    </div>
                  ))}
                </div>
              </div>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography
                    id="modal-modal-title"
                    variant="h4"
                    component="h2"
                  >
                    Please login First
                  </Typography>
                  <Typography
                    id="modal-modal-description"
                    sx={{ mt: 2 }}
                    style={{ fontSize: "15px" }}
                  >
                    You need to create an account for chat with Dealers
                  </Typography>
                  <div className="mt-4">
                    <button
                      className="clear-filter-btn button-transparent m-2"
                      onClick={handleClose}
                      aria-label="Search for used cars"
                    >
                      Cancel
                    </button>
                    <button
                      className="apply-filter-btn medium"
                      aria-label="Search for used cars"
                      onClick={() => {
                        window.location.href = "/signin";
                      }}
                    >
                      Go to Login
                    </button>
                  </div>
                </Box>
              </Modal>
              <div className="col-md-6">
                <div className="details-wrapper">
                  <h2 className="car-title">
                    {car?.brand} {car?.name}{" "}
                    <FavoriteIcon className="favorite-icon" />
                  </h2>
                  <h3 className="car-price">₹ {car?.rate}</h3>
                  <p className="car-summary">
                    {car?.fuelType} | {car?.kilometer} Km | {car?.transmission}
                  </p>
                  <div className="additional-info">
                    <div className="info-item">
                      <span className="info-label">Year:</span> {car?.year}
                    </div>
                    <div className="info-item">
                      <span className="info-label">Variant:</span>{" "}
                      {car?.varient}
                    </div>
                    <div className="info-item">
                      <span className="info-label">Body Type:</span>{" "}
                      {car?.bodyType}
                    </div>
                    <div className="info-item">
                      <span className="info-label">Mileage:</span>{" "}
                      {car?.mileage} km/l
                    </div>
                    <div className="info-item">
                      <span className="info-label">Seats:</span>{" "}
                      {car?.totalSeats}
                    </div>
                    <div className="info-item">
                      <span className="info-label">Ownership:</span>{" "}
                      {car?.ownership}
                    </div>
                    <div className="info-item">
                      <span className="info-label">Insurance Validity:</span>{" "}
                      {car?.insuranceValidity}
                    </div>
                    <div className="info-item">
                      <span className="info-label">Under Warranty:</span>{" "}
                      {car?.underWarrenty ? "Yes" : "No"}
                    </div>
                    <div className="info-item">
                      <span className="info-label">Price Negotiable:</span>{" "}
                      {car?.priceNegotiable ? "Yes" : "No"}
                    </div>
                    <div className="info-item">
                      <span className="info-label">RTO:</span> {car?.rto}
                    </div>
                    <div className="info-item">
                      <span className="info-label">Location:</span>{" "}
                      {car?.location}
                    </div>
                  </div>
                  <div className="action-buttons mt-4 row">
                    <div className="col-md-6 col-sm-6">
                      <BasicModal userId={car?.userId} />
                      {/* <button
                        className="chat-button mb-2 w-100"
                        onClick={goToChat}
                        aria-label="Search for used cars"
                      >
                        Chat with Dealer
                      </button> */}
                    </div>
                    <div className="col-md-6 col-sm-6">
                      <button
                        className="profile-button mb-4 w-100"
                        onClick={() =>
                          (window.location.href = `/dealer/profile/${car?.userId}`)
                        }
                        aria-label="Search for used cars"
                      >
                        Visit Dealer Profile
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default DetailsScreen;
