import React, { useState, useEffect } from "react";
import "./UpdateProfileScreen.css";
import { Formik, Field, Form } from "formik";
import { InputBase, Switch, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { editProfile, uploadToCloudinary } from "../../services/apis";
import { toast } from "react-toastify";
function UpdateProfileScreen() {
  const [imageUploading, setImageUploading] = useState(false);
  const [uid, setUid] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [isEditable, setIsEditable] = useState({
    firstName: false,
    email: false,
    phoneNumber: false,
  });
  const [privateAccount, setPrivateAccount] = useState(false);
  const [avatar, setAvatar] = useState(
    "https://t4.ftcdn.net/jpg/05/49/98/39/360_F_549983970_bRCkYfk0P6PP5fKbMhZMIb07mCJ6esXL.jpg"
  );
  const [userData, setUserData] = useState({
    firstName: "",
    email: "",
    phoneNumber: "",
    profileImage: "",
    privateAccount,
  });

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));

    if (user) {
      setUid(user.uid);
      setUserData({
        fullName: user.firstName || "",
        email: user.email || "",
        phoneNumber: user.phoneNumber || "",
        profileImage: user.profileImage || avatar,
        privateAccount: user.privateAccount || false,
      });
    }
  }, [avatar]);

  const handleImageUpload = async (e) => {
    setImageUploading(true);
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        setAvatar(reader.result);
        const uploadedImage = await uploadToCloudinary([reader.result]);
        setImageUploading(false);
        if (
          uploadedImage &&
          uploadedImage.data &&
          uploadedImage.data.length > 0
        ) {
          setAvatar(uploadedImage.data[0]);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div
      className="update-profile-screen saved-cars"
      style={{ marginTop: "137px" }}
    >
      <div className="container mt-5">
        <div className="card mt-5 shadow-lg p-4 rounded">
          <div className="row mt-5 mt-40">
            <div className="col-md-4 text-center">
              <div className="avatar-container position-relative">
                <img
                  src={avatar}
                  alt="Avatar"
                  className="avatar-img rounded-circle shadow"
                  loading="lazy"
                />
                <label htmlFor="upload-image">
                  <PhotoCamera style={{ fontSize: "30px" }} />
                  <input
                    type="file"
                    id="upload-image"
                    accept="image/*"
                    onChange={handleImageUpload}
                    style={{ display: "none" }}
                  />
                </label>
                {imageUploading && <>Image uploading....</>}
              </div>
            </div>
            <div className="col-md-8">
              <h2 className="font-md mb-4 text-primary">Update Profile</h2>
              <Formik
                initialValues={userData}
                enableReinitialize
                onSubmit={async (values) => {
                  values.profileImage = avatar;
                  values.privateAccount = privateAccount;

                  if (uid) {
                    setUpdating(true);
                    await editProfile(uid, values);
                    setUpdating(false);

                    toast.success("Profile updated", {
                      position: "top-center",
                      autoClose: 3000,
                    });
                    window.location.href = "/profile";
                  }
                }}
              >
                {({ values }) => (
                  <Form>
                    <div className="form-group mb-3">
                      <div className="d-flex align-items-center">
                        <Field
                          as={InputBase}
                          name="fullName"
                          value={values.fullName}
                          className={`form-control ${
                            isEditable.fullName ? "editable-field" : ""
                          }`}
                          disabled={!isEditable.fullName}
                          placeholder="Full Name"
                        />
                        <IconButton
                          onClick={() =>
                            setIsEditable((prev) => ({
                              ...prev,
                              fullName: !prev.fullName,
                            }))
                          }
                        >
                          <EditIcon />
                        </IconButton>
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <div className="d-flex align-items-center">
                        <Field
                          as={InputBase}
                          name="email"
                          value={values.email}
                          className={`form-control ${
                            isEditable.email ? "editable-field" : ""
                          }`}
                          disabled={!isEditable.email}
                          placeholder="Email"
                        />
                        <IconButton
                          onClick={() =>
                            setIsEditable((prev) => ({
                              ...prev,
                              email: !prev.email,
                            }))
                          }
                        >
                          <EditIcon />
                        </IconButton>
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <div className="d-flex align-items-center">
                        <Field
                          as={InputBase}
                          name="phoneNumber"
                          value={values.phoneNumber}
                          className={`form-control ${
                            isEditable.phoneNumber ? "editable-field" : ""
                          }`}
                          disabled={!isEditable.phoneNumber}
                          placeholder="Phone Number"
                        />
                        <IconButton
                          onClick={() =>
                            setIsEditable((prev) => ({
                              ...prev,
                              phoneNumber: !prev.phoneNumber,
                            }))
                          }
                        >
                          <EditIcon />
                        </IconButton>
                      </div>
                    </div>
                    {/* <div className="form-group mb-4">
                      <div className="d-flex align-items-center">
                        <span>Private Account</span>
                        <Switch
                          checked={privateAccount}
                          onChange={() => setPrivateAccount(!privateAccount)}
                          color="primary"
                        />
                      </div>
                      {privateAccount && (
                        <p className="" style={{ color: "red" }}>
                          Buyers can't see your contact information.
                        </p>
                      )}
                    </div> */}
                    <div className="d-flex justify-content-end">
                      <button
                        type="button"
                        className="btn btn-secondary me-3"
                        aria-label="Search for used cars"
                        onClick={() => (window.location.href = "/profile")}
                      >
                        Skip
                      </button>
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={updating}
                        aria-label="Search for used cars"
                      >
                        Save
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateProfileScreen;
