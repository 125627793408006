import React, { useEffect, useRef, useState } from "react";
// import "./MyProfile.css";
import useAuth from "../../hooks/useAuth";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { FaEllipsisV } from "react-icons/fa";
import { formattedDateTime } from "../../utils/time";
import DirectionsCarFilledIcon from "@mui/icons-material/DirectionsCarFilled";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useNavigate, useParams } from "react-router-dom";
import EditCar from "../EditCar/EditCar";
import Swal from "sweetalert2";
import HomeIcon from "@mui/icons-material/Home";
import DeleteIcon from "@mui/icons-material/Delete";
import EmptyState from "../../Components/EmptyState/EmptyState";
import Loader from "../../Components/Loader/Loader";
import TransitionsModal from "../../Components/Modal/Modal";
import ProfileCard from "../../Components/ProfileCard/ProfileCard";
import { dummyCarData } from "../../dummyData/cars";
import { auth } from "../../config/Firebase";
import {
  deleteCar,
  getDealerCarById,
  getProfileById,
} from "../../services/apis";
import MyCarCard from "../../Components/MyCarCard/MyCarCard";
import FavouriteScreen from "../FavouriteScreen/FavouriteScreen";

function DealerProfile() {
  const [myCars, setMyCars] = useState([]);
  const { id } = useParams();
  const [savedCars, setSavedCars] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [currentView, setCurrentView] = useState("list");
  const [selectedCar, setSelectedCar] = useState(null);
  const dropdownRefs = useRef({});
  const [value, setValue] = useState("1");
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [profile, setProfile] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [fetching, setFetching] = useState(false);
  const { uuid } = useAuth();
  const [uid, setUid] = useState(null);
  const user = auth.currentUser;

  const handleOpen = (car) => {
    setSelectedCar(car);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const handleChange = (event, newValue) => setValue(newValue);

  useEffect(() => {
    const fetchProfile = async () => {
      if (id) {
        setLoading(true);
        const res = await getProfileById(id);
        setLoading(false);
        if (res) {
          setProfile(res);
        }
      }
    };
    fetchProfile();
  }, [id]);

  useEffect(() => {
    const fetchMyCar = async () => {
      setLoading(true);
      const res = await getDealerCarById(uuid);

      setLoading(false);
      setMyCars(res);
    };
    fetchMyCar();
  }, [uuid]);

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - 200 &&
      hasMore &&
      !fetching &&
      myCars.length >= page * 10
    ) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [myCars, fetching, hasMore]);

  const handleAddCar = () => navigate("/add-car");

  const handleMenuClick = (carId) => {
    setActiveDropdown(activeDropdown === carId ? null : carId);
  };

  const handleClickOutside = (e) => {
    if (
      activeDropdown !== null &&
      (!dropdownRefs.current[activeDropdown] ||
        !dropdownRefs.current[activeDropdown].contains(e.target))
    ) {
      setActiveDropdown(null);
    }
  };

  const [savedCarLoading, setSavedCarsLoading] = useState(false);
  const getSavedCars = async () => {
    try {
      setSavedCars(dummyCarData);
    } catch (error) {
      setSavedCarsLoading(false);
    }
  };

  const handleEdit = (car) => {
    setSelectedCar(car);
    setCurrentView("edit");
    setActiveDropdown(null);
  };

  const handleRemove = async (carId) => {
    Swal.fire({
      title: "Are you sure to delte?",
      text: "This will delete permanently!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await deleteCar(carId);
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
        });
        window.location.reload();
      }
    });

    setActiveDropdown(null);
  };

  return (
    <div className="container ">
      {loading ? (
        <Loader />
      ) : (
        <>
          <section className="saved-cars" style={{ marginTop: "137px" }}>
            <div>
              <div className="space-around">
                <div>
                  <ProfileCard profile={profile} userId={id} />
                </div>
                <div style={{ width: "90%" }} className="m-10">
                  <div>
                    <h2>
                      <a href="/">
                        <HomeIcon
                          style={{ fontSize: "20px", color: "black" }}
                        />
                      </a>{" "}
                      <ArrowForwardIosIcon />{" "}
                      <a
                        href="/profile"
                        className="font-md"
                        style={{ fontSize: "20px", color: "black" }}
                      >
                        Profile
                      </a>
                    </h2>
                  </div>
                  {currentView === "list" ? (
                    <div className="mt-4">
                      <Box
                        sx={{
                          width: "100%",
                          typography: "body1",
                        }}
                      >
                        <TabContext value={value}>
                          <Box
                            sx={{
                              borderBottom: 1,
                              borderColor: "divider",
                              backgroundColor: "#f4f6f6",
                              borderRadius: 2,
                            }}
                          >
                            <TabList onChange={handleChange}>
                              <Tab
                                icon={
                                  <DirectionsCarFilledIcon
                                    style={{ fontSize: "1.5rem" }}
                                  />
                                }
                                label="All Cars"
                                value="1"
                                sx={{
                                  color: "grey",
                                  fontSize: "1.5rem",
                                  flexDirection: "row",
                                  textTransform: "none",
                                  "&.Mui-selected": {
                                    color: "#1e3e38",
                                  },
                                  minWidth: "auto",
                                }}
                              />

                              {/* <Tab
                                icon={<FavoriteIcon />}
                                label="Saved"
                                value="3"
                                onClick={getSavedCars}
                                sx={{
                                  color: "grey",
                                  fontSize: "1.5rem",
                                  flexDirection: "row",
                                  textTransform: "none",
                                  "&.Mui-selected": {
                                    color: "#1e3e38",
                                  },
                                  minWidth: "auto",
                                }}
                              /> */}
                            </TabList>
                          </Box>
                          <TabPanel value="1">
                            <div className="cards-container">
                              {myCars.length === 0 && !loading ? (
                                <EmptyState />
                              ) : (
                                myCars.map((car) => (
                                  <MyCarCard
                                    editable={false}
                                    car={car}
                                    onEdit={handleEdit}
                                    onDelete={handleRemove}
                                    onOpen={handleOpen}
                                  />
                                ))
                              )}
                            </div>
                          </TabPanel>
                        </TabContext>
                      </Box>
                    </div>
                  ) : (
                    <EditCar car={selectedCar ?? {}} />
                  )}
                </div>
              </div>
            </div>
            {selectedCar && (
              <TransitionsModal
                car={selectedCar}
                open={open}
                handleClose={handleClose}
              />
            )}
          </section>
        </>
      )}
    </div>
  );
}

export default DealerProfile;
