import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import FavoriteIcon from "@mui/icons-material/Favorite";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { Typography, Box } from "@mui/material";
import { useFavorites } from "../../context/FavoritesContext";

export default function CarCard({ car }) {
  const { favCars, addToFav } = useFavorites();
  const isFavourite = favCars.includes(car.id);
  return (
    <Card
      sx={{
        maxWidth: "100%",
        borderRadius: 4,
        boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.1)",
        transition: "transform 0.3s, box-shadow 0.3s",
        "&:hover": {
          transform: "scale(1.03)",
          boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.2)",
        },
      }}
      className="m-2"
    >
      <CardHeader
        action={
          <IconButton
            aria-label="settings"
            sx={{ color: isFavourite ? "red" : "black" }}
            onClick={() => addToFav(car.id)}
          >
            <FavoriteIcon style={{ fontSize: "21px" }} />
          </IconButton>
        }
      />
      <CardMedia
        onClick={() => (window.location.href = `/car/${car.id}`)}
        component="img"
        image={car?.images[0]}
        alt="Car"
        sx={{
          height: 200,
          // maxHeight: 100,
          objectFit: "contain",
          borderRadius: "0px 0px 20px 20px",
        }}
      />
      <CardContent
        sx={{ padding: "16px" }}
        onClick={() => (window.location.href = `/car/${car.id}`)}
      >
        <Typography
          variant="h4"
          sx={{
            fontWeight: "600",
            color: "#1a3d6d",
            marginBottom: "4px",
          }}
        >
          {car.name ?? "_"}
        </Typography>
        <Typography
          variant="h4"
          sx={{
            fontWeight: "bold",
            color: "#e53935",
            marginBottom: "6px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <CurrencyRupeeIcon sx={{ fontSize: "20px", marginRight: "4px" }} />
          {car.rate ?? "_"}
        </Typography>
        <Typography
          variant="body4"
          sx={{
            color: "#757575",
            marginBottom: "6px",
          }}
        >
          {car.fuelType ?? "_"} | {car.transmission ?? "_"} | {car.year ?? "_"}{" "}
          | {car.kilometer ?? "_"} km
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            color: "#424242",
            fontSize: "14px",
          }}
        >
          <LocationOnIcon sx={{ fontSize: "20px", marginRight: "6px" }} />
          Ernakulam
        </Box>
      </CardContent>
    </Card>
  );
}
