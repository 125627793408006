import React, { useEffect, useState } from "react";
import "./AdvertisementScreens.css";
import CarCard2 from "../../Components/CarCard2/CarCard2";
import { carBrands } from "../../dummyData/carBrands";
import { getRecommendedCars } from "../../services/apis";

function AdvertisementScreens() {
  const [cars, setCars] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchRecommendedCars = async () => {
      setLoading(true);
      try {
        const res = await getRecommendedCars();
        setCars(res);
      } catch (error) {
        console.error("Error fetching recommended cars:", error);
      }
      setLoading(false);
    };

    fetchRecommendedCars();
  }, []);
  return (
    <div className="p-5 advertisement-section">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-4">
            <div>
              <div className="space-between">
                <h2 className="font-md">Recommended Cars</h2>
                <a className="mt-4">View All</a>
              </div>
              <div className="box p-4">
                <div className="row">
                  {cars.map((car, index) => (
                    <div className="col-md-6">
                      <CarCard2 car={car} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="space-between">
              <h2 className="font-md">Find by Brands</h2>
              <a className="mt-4">View All</a>
            </div>
            <div className="box p-4">
              <div className="row">
                {carBrands.map((brand, index) => (
                  <div className="col-md-6">
                    <CarCard2 car={brand} />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div>
              <div className="space-between">
                <h2 className="font-md">Premium Cars</h2>
                <a className="mt-4">View All</a>
              </div>
              <div className="box p-4">
                <div className="row">
                  {cars.map((car, index) => (
                    <div className="col-md-6">
                      <CarCard2 car={car} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdvertisementScreens;
