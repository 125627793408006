import React, { useEffect, useState } from "react";
import "./LatestCars.css";
import Card from "../../Components/Card/Card";
import { getLatestCars } from "../../services/apis";
import Loader from "../../Components/Loader/Loader";
import MessageBox from "../../Components/MessageBox/MessageBox";
import { goToPageWithQuery } from "../../utils/helpers";

function LatestCars() {
  const [newCars, setNewCars] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchLatestCars = async () => {
      const cachedData = localStorage.getItem("latestCars");
      if (cachedData) {
        setNewCars(JSON.parse(cachedData));
        return;
      }

      setLoading(true);
      const res = await getLatestCars();
      setLoading(false);
      if (res && res.length > 0) {
        setNewCars(res);
        localStorage.setItem("latestCars", JSON.stringify(res));
      }
    };
    fetchLatestCars();
  }, []);

  return (
    <div className="">
      <div className="container">
        <h2 className="font-md text-center mt-5">LATEST CARS</h2>
        {loading ? (
          <Loader />
        ) : newCars.length == 0 ? (
          <div>
            <MessageBox title={"No cars found!"} type={"not-found"} />
          </div>
        ) : (
          <div className="cars-grid mt-4">
            {newCars.slice(0, 5).map((car, index) => (
              <Card car={car} key={index} />
            ))}
          </div>
        )}

        <div className="text-center">
          <button
            className="button-transparent mt-4 mb-5"
            onClick={() => goToPageWithQuery(`search=latest`)}
            aria-label="Search for used cars"
          >
            View All
          </button>
        </div>
      </div>
    </div>
  );
}

export default LatestCars;
