import { getDatabase, ref, onDisconnect, set } from "firebase/database";
import { auth } from "../config/Firebase";
import { serverTimestamp } from "firebase/firestore";

// const db = getDatabase();

export const updatePresence = () => {
  const db = getDatabase();
  const user = auth.currentUser;

  if (!user) return;

  const userStatusRef = ref(db, `/presence/${user.uid}`);

  set(userStatusRef, {
    state: "online",
    lastChanged: serverTimestamp(),
  });

  onDisconnect(userStatusRef).set({
    state: "offline",
    lastChanged: serverTimestamp(),
  });
};

export const goToPageWithQuery = (query = null) => {
  if (query) {
    window.location.href = `/used-cars?${query}`;
  } else {
    window.location.href = `/used-cars`;
  }
};
