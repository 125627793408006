import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { db, auth } from "../../config/Firebase";
import { collection, onSnapshot } from "firebase/firestore";
import { getDatabase, ref, onValue } from "firebase/database";
import "./ChatScreen.css";
import PersonIcon from "@mui/icons-material/Person";
import useMobileView from "../../hooks/isMobile";
import ChatScreen from "./ChatScreen";
import Loader from "../../Components/Loader/Loader";
import { getChatsByIds } from "../../services/apis";
import useAuth from "../../hooks/useAuth";

function Home() {
  const [users, setUsers] = useState([]);
  const [onlineStatus, setOnlineStatus] = useState({});
  const [selectedUser, setSelectedUser] = useState(null);
  const navigate = useNavigate();
  const user = auth.currentUser;
  const isMobile = useMobileView();
  const [loading, setLoading] = useState(false);
  const [chats, setChats] = useState([]);
  const { currentUser, uid } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      console.log("suer---------", user ? user : "no user");
      console.log('current user-------', currentUser, uid)
      if (user && user.uid) {
        console.log("suer uid-------", user.uid);
        const data = await getChatsByIds(user.uid);

        if (data && data.length > 0) {
          setChats(data);
        }
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    setLoading(true);
    const unsub = onSnapshot(collection(db, "users"), (snapshot) => {
      setLoading(false);
      setUsers(snapshot.docs.map((doc) => doc.data()));
    });

    const dbRef = getDatabase();
    const statusRef = ref(dbRef, "/status");
    onValue(statusRef, (snapshot) => {
      setOnlineStatus(snapshot.val() || {});
    });

    return unsub;
  }, []);

  const handleUserClick = (receiverData) => {
    setSelectedUser(receiverData);

    if (isMobile && receiverData) {
      navigate(`/chat/${receiverData.userId}`, { state: { receiverData } });
    }
  };

  return (
    <div className="home-container" style={{ marginTop: "137px" }}>
      <div className="sidebar">
        <header className="sidebar-header">
          <h4>{user?.displayName}</h4>
        </header>
        <div className="user-list">
          {loading && <Loader />}
          {chats.length === 0 && !loading ? (
            <>No chat yet</>
          ) : (
            chats.map((u) => (
              <div
                key={u.userId}
                className="user-list-item"
                onClick={() => handleUserClick(u)}
              >
                <PersonIcon className="avatar" />
                <div className="user-info">
                  <p className="user-name">{u.firstName}</p>
                  <p className="user-status">
                    <span
                      className={`online-indicator ${
                        onlineStatus[u.userId]?.state === "online"
                          ? "active"
                          : ""
                      }`}
                    ></span>
                    {onlineStatus[u.userId]?.state === "online"
                      ? "Online"
                      : "Offline"}
                  </p>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
      {!isMobile && selectedUser && (
        <div className="chat-screen-container">
          <ChatScreen receiverData={selectedUser} />
        </div>
      )}
    </div>
  );
}

export default Home;
