import { createContext, useContext, useState, useEffect } from "react";
import { toast } from "react-toastify";

const FavoritesContext = createContext();

export const FavoritesProvider = ({ children }) => {
  const [favCars, setFavCars] = useState([]);

  useEffect(() => {
    const storedFavs = JSON.parse(localStorage.getItem("fav-cars")) || [];
    setFavCars(storedFavs);
  }, []);

  const addToFav = (id) => {
    let updatedFavCars = [...favCars];

    if (!updatedFavCars.includes(id)) {
      updatedFavCars.push(id);
      toast.success("Added to favourites");
    } else {
      updatedFavCars = updatedFavCars.filter((favId) => favId !== id);
      toast.error("Removed from favourites");
    }

    setFavCars(updatedFavCars);
    localStorage.setItem("fav-cars", JSON.stringify(updatedFavCars));
  };

  return (
    <FavoritesContext.Provider value={{ favCars, addToFav }}>
      {children}
    </FavoritesContext.Provider>
  );
};

export const useFavorites = () => useContext(FavoritesContext);
