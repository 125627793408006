import React from "react";
import "./Banner.css";

function Banner() {
  return (
    <div className="banner">
      <div className="banner-content">
        <div className="container">
          <h2 className="font-lg">
            Buy & Sell Used Cars
            <br /> Fast, Easy & Secure
          </h2>
          <div>
            <p className="font-text-grey line-h-md font-medium font-weight-500">
              Our platform connects car sellers with buyers seamlessly. Sell
              your car by posting a free
              <br /> listing and reach thousands of potential buyers
            </p>
          </div>
        </div>
        <div className="mt-4">
          <button
            aria-label="Search for used cars"
            className="large"
            onClick={() => (window.location.href = "/used-cars")}
          >
            Shop Now
          </button>
        </div>
      </div>
      <div className="banner-image">
        <img
          src="https://cdn.pixabay.com/photo/2015/10/01/19/05/car-967470_640.png"
          loading="lazy"
        />
      </div>
    </div>
  );
}

export default Banner;
