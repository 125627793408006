// import React from "react";

// function CarsByBrands() {
 
//   return (
//     <div>
//       <div style={{ backgroundColor: "#fffaf3" }}>
//         <div className="container">
//           <h2 className="font-md text-center">Cars by Brands</h2>

//           <div className="cards-container">
//             {loading ? (
//               <Loader />
//             ) : error ? (
//               <>Error: {error}</>
//             ) : cars[category].length === 0 ? (
//               <>
//                 <MessageBox title={"No cars found!"} type={"not-found"} />
//               </>
//             ) : (
//               cars.map((car) => (
//                 <div
//                   onClick={() => (window.location.href = `/car/${car.id}`)}
//                   key={car.id}
//                 >
//                   <CarCard car={car} />
//                 </div>
//               ))
//             )}
//           </div>

//           <div className="text-center">
//             <button className="button-transparent mt-4 mb-5">View All</button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default CarsByBrands;


import React from 'react'

function CarsByBrands() {
  return (
    <div>CarsByBrands</div>
  )
}

export default CarsByBrands