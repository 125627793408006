import { createContext, useContext, useState, useEffect } from "react";
import { getLatestCars } from "../services/apis";

const CarsContext = createContext();

export const LatestCarsProvider = ({ children }) => {
  const [newCars, setNewCars] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isFetched, setIsFetched] = useState(false);

  useEffect(() => {
    const fetchLatestCars = async () => {
      if (isFetched) return;
      setLoading(true);
      const res = await getLatestCars();
      setLoading(false);
      if (res && res.length > 0) {
        setNewCars(res);
        setIsFetched(true);
      }
    };
    fetchLatestCars();
  }, [isFetched]);

  return (
    <CarsContext.Provider value={{ newCars, loading }}>
      {children}
    </CarsContext.Provider>
  );
};

export const useCars = () => useContext(CarsContext);
