import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { db, auth } from "../../config/Firebase";
import {
  addDoc,
  collection,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore";
import "./ChatScreen.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function ChatScreenMobile() {
  const location = useLocation();
  //  const navigate = useNavigate();
  const receiverData = location.state?.receiverData;
  const [allMessages, setAllMessages] = useState([]);
  const [chatMessage, setChatMessage] = useState("");
  const user = auth.currentUser;
  const navigate = useNavigate();

  useEffect(() => {
    if (receiverData.userId) {
      const unsub = onSnapshot(
        query(
          collection(
            db,
            "users",
            user.uid,
            "chatUsers",
            receiverData.userId,
            "messages"
          ),
          orderBy("timestamp")
        ),
        (snapshot) => {
          setAllMessages(
            snapshot.docs.map((doc) => ({
              id: doc.id,
              messages: doc.data(),
            }))
          );
        }
      );
      return unsub;
    }
  }, [receiverData.userId]);

  const sendMessage = async () => {
    if (chatMessage.trim()) {
      await addDoc(
        collection(
          db,
          "users",
          user.uid,
          "chatUsers",
          receiverData.userId,
          "messages"
        ),
        {
          username: user.displayName,
          messageUserId: user.uid,
          message: chatMessage,
          timestamp: new Date(),
        }
      );
      await addDoc(
        collection(
          db,
          "users",
          receiverData.userId,
          "chatUsers",
          user.uid,
          "messages"
        ),
        {
          username: user.displayName,
          messageUserId: user.uid,
          message: chatMessage,
          timestamp: new Date(),
        }
      );
      setChatMessage("");
    }
  };

  return (
    <div className="chat-container">
      <header className="chat-header">
        <div className="chat-header-left">
          <ArrowBackIcon className="back-icon" onClick={() => navigate("/")} />
          <h4>{receiverData.firstName}</h4>
        </div>
      </header>
      <div className="messages">
        {allMessages.map(({ id, messages }) => (
          <div
            key={id}
            className={`message ${
              messages.messageUserId === user.uid ? "outgoing" : "incoming"
            }`}
          >
            {messages.message}
          </div>
        ))}
      </div>
      <div className="message-input-container">
        <input
          type="text"
          placeholder="Type a message"
          value={chatMessage}
          onChange={(e) => setChatMessage(e.target.value)}
        />
        <button onClick={sendMessage} aria-label="Search for used cars">Send</button>
      </div>
    </div>
  );
}

export default ChatScreenMobile;
