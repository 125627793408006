import {
  collection,
  query,
  where,
  getDocs,
  addDoc,
  limit,
  startAfter,
  getDoc,
  doc,
  setDoc,
  updateDoc,
  deleteDoc,
  orderBy,
  serverTimestamp,
} from "firebase/firestore";
import { auth, db } from "../config/Firebase";
import axios from "axios";
import { dummyCarData } from "../dummyData/cars";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { BACKEND_URL } from "../constants/urls";

export const signUp = async (data) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      data.email,
      data.password
    );

    const user = userCredential.user;
    localStorage.setItem("user", JSON.stringify({ ...user, ...data }));

    setDoc(doc(db, "users", user.uid), {
      ...data,
      userId: user.uid,
      timestamp: new Date(),
    });

    return {
      success: true,
      message: "User signed in successfully",
    };
  } catch (error) {
    return {
      success: false,
      message: "Email / phone number / username already exist",
      error,
    };
  }
};

export const login = async (data) => {
  try {
    let user = null;
    signInWithEmailAndPassword(auth, data.email, data.password)
      .then((userCredential) => {
        user = userCredential.user;
        localStorage.setItem("user", JSON.stringify(user));
        return { success: true, message: "User logged in successfully", user };
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;

        console.log("Error while signin : ", { errorCode, errorMessage });
        return { success: false, message: "Invalid credentials", error };
      });
  } catch (error) {
    return { success: false, message: "Error during login", error };
  }
};

export const uploadToCloudinary = async (images) => {
  try {
    let resp = await axios.post(`${BACKEND_URL}/api/v1/user/image/upload`, {
      images,
    });
    return resp;
  } catch (error) {
    console.error("Error uploading to Cloudinary", error);
    return null;
  }
};

export const addCar = async (carData) => {
  try {
    const { images } = carData;

    if (!images || images.length === 0) {
      return { success: false, message: "Please select at least one image" };
    }

    const imageUrl = await uploadToCloudinary(images);

    if (imageUrl && imageUrl.data.length > 0) {
      // uploadedImages = imageUrl.data;
    }

    // await addDoc(collection(db, "cars"), carData);
    await addDoc(collection(db, "cars"), {
      ...carData,
      createdAt: serverTimestamp(),
    });

    return { success: true, message: "Car added successfully" };
  } catch (error) {
    console.error("Error adding car to Firestore", error);
    return { success: false, message: "Failed to add car" };
  }
};

export const addDummyCars = async () => {
  try {
    const carDataArray = dummyCarData;
    const promises = carDataArray.map(async (carData) => {
      const docRef = await addDoc(collection(db, "cars"), carData);
      return docRef.id;
    });

    const carIds = await Promise.all(promises);

    return {
      success: true,
      message: "Cars added successfully",
      carIds,
    };
  } catch (error) {
    return {
      success: false,
      message: "Error while adding cars",
      error,
    };
  }
};

export const getAllCars = async (filters, pagination = null) => {
  try {
    const carsCollection = collection(db, "cars");
    let q = query(carsCollection);

    console.log("Filters received in API:", filters); // Debugging

    if (filters) {
      // Price Range Filter
      if (
        filters.priceRange &&
        filters.priceRange.min != null &&
        filters.priceRange.max != null
      ) {
        q = query(
          q,
          where("rate", ">=", filters.priceRange.min),
          where("rate", "<=", filters.priceRange.max)
        );
      }

      // Brands Filter
      if (filters.brands && filters.brands.length > 0) {
        q = query(q, where("brand", "in", filters.brands));
      }

      // Body Types Filter
      if (filters.bodyTypes && filters.bodyTypes.length > 0) {
        q = query(q, where("bodyType", "in", filters.bodyTypes));
      }

      // Fuel Types Filter
      if (filters.fuelTypes && filters.fuelTypes.length > 0) {
        q = query(q, where("fuelType", "in", filters.fuelTypes));
      }

      // Transmission Types Filter
      if (filters.transmissionTypes && filters.transmissionTypes.length > 0) {
        q = query(q, where("transmission", "in", filters.transmissionTypes));
      }

      // Year Filter
      if (filters.year && filters.year.includes(" & ")) {
        const [minYear, maxYear] = filters.year.split(" & ");
        if (minYear && maxYear) {
          q = query(
            q,
            where("year", ">=", parseInt(minYear, 10)),
            where("year", "<=", parseInt(maxYear, 10))
          );
        }
      }

      // Ownership Filter
      if (filters.ownership && filters.ownership.length > 0) {
        q = query(q, where("ownership", "in", filters.ownership));
      }
    }

    // Pagination
    if (pagination) {
      const { pageSize, lastDoc } = pagination;
      if (pageSize) q = query(q, limit(pageSize));
      if (lastDoc) q = query(q, startAfter(lastDoc));
    }

    const querySnapshot = await getDocs(q);
    const cars = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return cars;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getCarDetails = async (id) => {
  try {
    const carDoc = doc(db, "cars", id);
    const carSnapshot = await getDoc(carDoc);

    if (carSnapshot.exists()) {
      return { id: carSnapshot.id, ...carSnapshot.data() };
    } else {
      throw new Error("Car not found");
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const deleteCar = async (id) => {
  try {
    const carDoc = doc(db, "cars", id);
    await deleteDoc(carDoc);
    return `Car with ID ${id} deleted successfully`;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getDealerProfile = async (userId) => {
  try {
    const userQuery = query(
      collection(db, "users"),
      where("__name__", "==", userId)
    );
    const userSnapshot = await getDocs(userQuery);

    if (userSnapshot.empty) {
      return { success: false, message: "User not found" };
    }

    const user = userSnapshot.docs[0].data();

    const carsQuery = query(
      collection(db, "cars"),
      where("userId", "==", userId)
    );
    const carsSnapshot = await getDocs(carsQuery);

    const cars = carsSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return { success: true, user: { ...user, cars } };
  } catch (error) {
    return { success: false, message: "Failed to fetch dealer profile", error };
  }
};

export const getCarsByIds = async (ids) => {
  try {
    const carPromises = ids.map(async (id) => {
      const carDoc = doc(db, "cars", id);
      const carSnapshot = await getDoc(carDoc);
      if (carSnapshot.exists()) {
        return { id: carSnapshot.id, ...carSnapshot.data() };
      }
    });
    const cars = await Promise.all(carPromises);
    return cars.filter((car) => car !== undefined);
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getDealerCarById = async (dealerId) => {
  try {
    const q = query(collection(db, "cars"), where("userId", "==", dealerId));
    const querySnapshot = await getDocs(q);
    const cars = [];
    querySnapshot.forEach((doc) => {
      cars.push({ id: doc.id, ...doc.data() });
    });
    return cars;
  } catch (error) {
    console.log("Error while fetching dealer cars");
    return { success: false, message: "Failed to fetch dealer cars" };
  }
};

export const getProfileById = async (profileId) => {
  try {
    const userDoc = doc(db, "users", profileId);
    const userSnapshot = await getDoc(userDoc);

    if (userSnapshot.exists()) {
      return { id: userSnapshot.id, ...userSnapshot.data() };
    } else {
      throw new Error("User not found");
    }
  } catch (error) {
    console.log("Error while fetching profile : ", error);
    return { success: false, message: "Error while fetching profile" };
  }
};

export const editProfile = async (userId, updatedData) => {
  try {
    const userDocRef = doc(db, "users", userId);
    await updateDoc(userDocRef, updatedData);

    return { success: true, message: "profile updated successfully" };
  } catch (error) {
    console.error("Error updating car in Firestore", error);
    return { success: false, message: "Failed to update profil" };
  }
};

export const editCar = async (carId, updatedCarData) => {
  try {
    const { images } = updatedCarData;

    if (images && images.length > 0) {
      const imageUrl = await uploadToCloudinary(images);

      if (imageUrl && imageUrl.data.length > 0) {
        updatedCarData.images = imageUrl.data;
      }
    }

    const carDocRef = doc(db, "cars", carId);
    await updateDoc(carDocRef, updatedCarData);

    return { success: true, message: "Car updated successfully" };
  } catch (error) {
    console.error("Error updating car in Firestore", error);
    return { success: false, message: "Failed to update car" };
  }
};

export const searchCars = async (searchTerm) => {
  try {
    const searchTermLower = searchTerm.toLowerCase();
    const carsRef = collection(db, "cars");
    const querySnapshot = await getDocs(carsRef);

    const cars = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      if (
        data.brand?.toLowerCase().includes(searchTermLower) ||
        data.name?.toLowerCase().includes(searchTermLower) ||
        data.year?.toString().includes(searchTermLower)
      ) {
        cars.push({ id: doc.id, ...data });
      }
    });

    return cars;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const addDealerToChatList = async (chatData) => {
  try {
    await addDoc(collection(db, "chats"), chatData);

    return { success: true, message: "chatData added successfully" };
  } catch (error) {
    console.error("Error adding chatData to Firestore", error);
    return { success: false, message: "Failed to add chatData" };
  }
};

export const getChatsByIds = async (myId) => {
  try {
    const q = query(collection(db, "chats"), where("myId", "==", myId));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => doc.data());
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getLatestCars = async () => {
  try {
    const carsCollection = collection(db, "cars");
    const q = query(carsCollection, orderBy("createdAt", "desc"), limit(10));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getCarsByBodyType = async (bodyType) => {
  try {
    const carsCollection = collection(db, "cars");
    const q = query(carsCollection, where("bodyType", "==", bodyType));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) return [];

    return querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getRecommendedCars = async () => {
  try {
    const carsCollection = collection(db, "cars");
    let q = query(carsCollection, orderBy("createdAt", "desc"), limit(5));

    const querySnapshot = await getDocs(q);
    const recommendedCars = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return recommendedCars;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getCarsByBrand = async (brandName) => {
  try {
    const normalizedBrand = brandName.toLowerCase();
    const carsCollection = collection(db, "cars");

    const q = query(carsCollection, where("brandLower", "==", normalizedBrand));
    const querySnapshot = await getDocs(q);

    const cars = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return cars;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getStatCount = async () => {
  try {
    const carsCollection = collection(db, "cars");
    const usersCollection = collection(db, "users");

    const carsSnapshot = await getDocs(carsCollection);
    const usersSnapshot = await getDocs(usersCollection);

    const carsCount = carsSnapshot.size;
    const usersCount = usersSnapshot.size;

    const brandsSet = new Set();
    carsSnapshot.forEach((doc) => {
      const brand = doc.data().brand;
      if (brand) brandsSet.add(brand.toLowerCase());
    });

    return {
      cars: carsCount,
      customers: usersCount,
      brands: brandsSet.size,
    };
  } catch (error) {
    throw new Error(error.message);
  }
};
