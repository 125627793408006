export const years = [
  {
    key: 1,
    text: "Between 2021 & 2026",
    value: [2021, 2026],
  },
  {
    key: 2,
    text: "Between 2016 & 2020",
    value: [2016, 2020],
  },
  {
    key: 3,
    text: "Between 2010 & 2015",
    value: [2010, 2015],
  },
  {
    key: 4,
    text: "Between 2005 & 2009",
    value: [2005, 2009],
  },
  {
    key: 5,
    text: "Between 2000 & 2004",
    value: [2000, 2004],
  },
  {
    key: 6,
    text: "Between 1995 & 1999",
    value: [1995, 1999],
  },
  {
    key: 7,
    text: "Below 1995",
    value: [1995, 1500],
  },
];
