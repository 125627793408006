export const carFeatures = [
  { index: 1, text: "Sunroof", value: "sunroof" },
  { index: 2, text: "Leather Seats", value: "leather_seats" },
  { index: 3, text: "Heated Seats", value: "heated_seats" },
  { index: 4, text: "Bluetooth Connectivity", value: "bluetooth_connectivity" },
  { index: 5, text: "Navigation System", value: "navigation_system" },
  { index: 6, text: "Rear Camera", value: "rear_camera" },
  { index: 7, text: "Parking Sensors", value: "parking_sensors" },
  { index: 8, text: "Keyless Entry", value: "keyless_entry" },
  { index: 9, text: "Push Button Start", value: "push_button_start" },
  {
    index: 10,
    text: "Adaptive Cruise Control",
    value: "adaptive_cruise_control",
  },
  { index: 11, text: "Power Steering", value: "power_steering" },
  { index: 12, text: "ABS (Anti-lock Braking System)", value: "abs" },
  { index: 13, text: "Airbags", value: "airbags" },
  { index: 14, text: "Climate Control", value: "climate_control" },
  { index: 15, text: "Power Windows", value: "power_windows" },
  { index: 16, text: "Power Mirrors", value: "power _mirrors" },
  { index: 17, text: "Traction Control", value: "traction_control" },
  { index: 18, text: "Hill Assist", value: "hill_assist" },
  {
    index: 19,
    text: "Daytime Running Lights",
    value: "daytime_running_lights",
  },
  { index: 20, text: "Fog Lights", value: "fog_lights" },
  { index: 21, text: "Automatic Headlights", value: "automatic_headlights" },
  { index: 22, text: "Rain Sensing Wipers", value: "rain_sensing_wipers" },
  { index: 23, text: "Remote Start", value: "remote_start" },
  { index: 24, text: "Wireless Charging", value: "wireless_charging" },
  { index: 25, text: "Reversing Sensors", value: "reversing_sensors" },
  {
    index: 26,
    text: "Front Seat Ventilation",
    value: "front_seat_ventilation",
  },
  {
    index: 27,
    text: "Rear Seat Entertainment",
    value: "rear_seat_entertainment",
  },
  { index: 28, text: "Ambient Lighting", value: "ambient_lighting" },
  {
    index: 29,
    text: "Electric Parking Brake",
    value: "electric_parking_brake",
  },
  { index: 30, text: "Surround View Camera", value: "surround_view_camera" },
  { index: 31, text: "Blind Spot Monitoring", value: "blind_spot_monitoring" },
  {
    index: 32,
    text: "Lane Departure Warning",
    value: "lane_departure_warning",
  },
  {
    index: 33,
    text: "Forward Collision Warning",
    value: "forward_collision_warning",
  },
  {
    index: 34,
    text: "Automatic Emergency Braking",
    value: "automatic_emergency_braking",
  },
  { index: 35, text: "Pedestrian Detection", value: "pedestrian_detection" },
  { index: 36, text: "Lane Keep Assist", value: "lane_keep_assist" },
];
