import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import HomeScreen from "./screens/HomeScreen/HomeScreen";
import Footer from "./Components/Footer/Footer";
import Header from "./Components/Header/Header";
import DetailsScreen from "./screens/DetailsScreen/DetailsScreen";
import DealerProfile from "./screens/DealerProfile/DealerProfile";
import LoginScreen from "./screens/LoginScreen/LoginScreen";
import SignUpScreen from "./screens/SignUpScreen/SignUpScreen";
import MyProfile from "./screens/MyProfile/MyProfile";
import AddCar from "./screens/AddCar/AddCar";
import BlogsScreen from "./screens/BlogsScreen/BlogsScreen";
import ChatScreen from "./screens/ChatScreen/ChatScreen";
import Home from "./screens/ChatScreen/Home";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./config/Firebase";
import { updatePresence } from "./utils/helpers";
import ExploreCarsScreen from "./screens/ExploreCarsScreen/ExploreCarsScreen";
import ReviewScreen from "./screens/ReviewScreen/ReviewScreen";
import FavouriteScreen from "./screens/FavouriteScreen/FavouriteScreen";
import AboutUsScreen from "./screens/AboutUsScreen/AboutUsScreen";
import ContactScreen from "./screens/ContactScreen/ContactScreen";
import UpdateProfileScreen from "./screens/UpdateProfileScreen/UpdateProfileScreen";
import ChatScreenMobile from "./screens/ChatScreen/ChatScreenMobile";
import CarsByBrands from "./screens/CarsByBrands/CarsByBrands";
import { LatestCarsProvider } from "./context/LatestCarsContext";
import { FavoritesProvider } from "./context/FavoritesContext";
import { ToastContainer } from "react-toastify";
import { HelmetProvider } from "react-helmet-async";

function App() {
  const Layout = ({ children }) => {
    const { pathname } = useLocation();
    const isAuthPage = pathname === "/signin" || pathname === "/signup";

    useEffect(() => {
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          updatePresence();
        }
      });

      return () => unsubscribe();
    }, []);

    return (
      <>
        {!isAuthPage && <Header />}
        <main>{children}</main>
        {!isAuthPage && <Footer />}
      </>
    );
  };

  return (
    <BrowserRouter>
      <HelmetProvider>
        <FavoritesProvider>
          <LatestCarsProvider>
            <Layout>
              <Routes>
                <Route path="/" element={<HomeScreen />} />
                <Route path="/details/:id" element={<DetailsScreen />} />
                <Route path="/dealer/profile/:id" element={<DealerProfile />} />
                <Route path="/signin" element={<LoginScreen />} />
                <Route path="/signup" element={<SignUpScreen />} />
                <Route path="/profile" element={<MyProfile />} />
                <Route path="/add-car" element={<AddCar />} />
                <Route path="/blogs" element={<BlogsScreen />} />
                {/* <Route path="/chat-home/:receiverId" element={<ChatScreen />} /> */}
                <Route path="/chat" element={<Home />} />
                <Route path="/chat/:id" element={<ChatScreenMobile />} />
                <Route path="/used-cars" element={<ExploreCarsScreen />} />
                <Route path="/car/:id" element={<DetailsScreen />} />
                <Route path="/reviews" element={<ReviewScreen />} />
                <Route path="/saved-cars" element={<FavouriteScreen />} />
                <Route path="/about-us" element={<AboutUsScreen />} />
                <Route path="/contact-us" element={<ContactScreen />} />
                <Route
                  path="/update-profile"
                  element={<UpdateProfileScreen />}
                />
                <Route path="/cars-by-brand" element={<CarsByBrands />} />
              </Routes>
            </Layout>
          </LatestCarsProvider>
        </FavoritesProvider>
        <ToastContainer />
      </HelmetProvider>
    </BrowserRouter>
  );
}

export default App;
