import React, { useState } from "react";
import "./ProfileCard.css";
import useAuth from "../../hooks/useAuth";
import EditIcon from "@mui/icons-material/Edit";
import ShareIcon from "@mui/icons-material/Share";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { NO_IMAGE } from "../../constants/urls";
import Loader from "../Loader/Loader";
import LocationOnIcon from "@mui/icons-material/LocationOn";
function ProfileCard({ editable, profile, userId }) {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [loadings, setLoading] = useState(false);

  const handleEditProfile = () => {
    navigate("/update-profile");
  };

  const handleShareProfile = () => {
    console.log(
      "currentUser------",
      currentUser ? currentUser : "no currentUser"
    );
    if (currentUser && currentUser.uid) {
      const profileUrl = `${window.location.origin}/profile/${currentUser.uid}`;
      navigator.clipboard
        .writeText(profileUrl)
        .then(() => {
          toast.success("Profile URL copied to clipboard!", {
            position: "top-center",
            autoClose: 3000,
          });
        })
        .catch((err) => {
          console.error("Failed to copy the URL:", err);
          toast.error("Failed to copy the profile URL. Please try again.", {
            position: "top-center",
            autoClose: 3000,
          });
        });
    } else {
      toast.warn("User ID is missing. Unable to generate the profile URL.", {
        position: "top-center",
        autoClose: 3000,
      });
    }
  };

  return (
    <div>
      <div className="profile-card">
        {loadings ? (
          <Loader />
        ) : (
          <>
            <img
              src={profile?.profileImage ?? NO_IMAGE}
              alt="Profile"
              className="image-circle"
              loading="lazy"
            />
            <div className="mt-4">
              <h3>{profile?.fullName || "_"}</h3>
              <p>
                <LocationOnIcon />
                {profile?.location ?? "_"}
              </p>

              <div className="space-around mt-4 gap-10">
                {editable && (
                  <button
                    onClick={handleEditProfile}
                    className="button-md primary"
                    aria-label="Search for used cars"
                  >
                    <EditIcon />
                    Edit Profile
                  </button>
                )}

                <button
                  onClick={handleShareProfile}
                  className="button-md transparent"
                  aria-label="Search for used cars"
                >
                  <ShareIcon />
                  Share Profile
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default ProfileCard;
