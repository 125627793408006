import React, { useEffect, useState } from "react";
import "./FavouriteScreen.css";
import { getCarsByIds } from "../../services/apis";
import Loader from "../../Components/Loader/Loader";
import EmptyState from "../../Components/EmptyState/EmptyState";

const FavouriteScreen = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const carsPerPage = 5;
  const [savedCars, setSavedCars] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchSavedCars = async () => {
    try {
      setLoading(true);
      let favCars = JSON.parse(localStorage.getItem("fav-cars")) || [];
      if (favCars.length > 0) {
        const res = await getCarsByIds(favCars);
        setSavedCars(res || []);
      } else {
        setSavedCars([]);
      }
    } catch (error) {
      console.error("Error while fetching saved cars: ", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSavedCars();
  }, []);

  const removeFromFavorites = (id) => {
    let favCars = JSON.parse(localStorage.getItem("fav-cars")) || [];
    const updatedFavCars = favCars.filter((favId) => favId !== id);
    localStorage.setItem("fav-cars", JSON.stringify(updatedFavCars));
    fetchSavedCars();
  };

  const totalPages = Math.max(1, Math.ceil(savedCars.length / carsPerPage));
  const currentCars = savedCars.slice(
    (currentPage - 1) * carsPerPage,
    currentPage * carsPerPage
  );

  return (
    <section className="saved-cars-section saved-cars">
      <div className="saved-cars-container">
        <h2 className="saved-cars-title">Saved Cars</h2>
        <div className="saved-cars-list">
          {loading ? (
            <Loader />
          ) : savedCars.length > 0 ? (
            currentCars.length > 0 ? (
              currentCars.map((car) => (
                <div key={car._id} className="saved-car-row">
                  <img
                    src={car.images[0]}
                    alt={car.name}
                    className="saved-car-image"
                    loading="lazy"
                  />
                  <div className="saved-car-details">
                    <h3 className="saved-car-name">{car.name}</h3>
                    <p className="saved-car-price">
                      ₹{car.rate.toLocaleString()}
                    </p>
                    <p className="saved-car-info">
                      {car.year} • {car.fuelType} • {car.transmission}
                    </p>
                    <p className="saved-car-location">Location: {car.place}</p>
                  </div>
                  <button
                    className="saved-car-remove-btn"
                    onClick={() => removeFromFavorites(car.id)}
                    aria-label="Search for used cars"
                  >
                    Remove
                  </button>
                </div>
              ))
            ) : (
              <EmptyState />
            )
          ) : (
            <EmptyState />
          )}
        </div>
        {savedCars.length > 0 && (
          <div className="pagination">
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index}
                className={`pagination-btn ${
                  currentPage === index + 1 ? "active-page" : ""
                }`}
                onClick={() => setCurrentPage(index + 1)}
                aria-label="Search for used cars"
              >
                {index + 1}
              </button>
            ))}
          </div>
        )}
      </div>
    </section>
  );
};

export default FavouriteScreen;
