import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { FaEllipsisV } from "react-icons/fa";
import FavoriteIcon from "@mui/icons-material/Favorite";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

export default function MyCarCard({ editable, car, onEdit, onDelete, onOpen }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCarId, setSelectedCarId] = useState(null);

  const handleMenuClick = (event, carId) => {
    setAnchorEl(event.currentTarget);
    setSelectedCarId(carId);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedCarId(null);
  };

  const handleOpenClick = () => {
    if (onOpen) {
      onOpen(car);
    }
  };

  const handleEditClick = () => {
    if (onEdit) {
      onEdit(car);
    }
    handleMenuClose();
  };

  const handleDeleteClick = () => {
    if (onDelete && car && car.id) {
      onDelete(car.id);
    } else {
      console.error("Error: Car or car._id is missing.");
    }
    handleMenuClose();
  };

  return (
    <Card
      sx={{
        maxWidth: "100%",
        borderRadius: 4,
        boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.1)",
        transition: "transform 0.3s, box-shadow 0.3s",
      }}
      className="m-2"
    >
      <CardHeader
        action={
          <IconButton
            onClick={(event) => handleMenuClick(event, car._id)}
            aria-controls="dropdown-menu"
            aria-haspopup="true"
          >
            <FaEllipsisV />
          </IconButton>
        }
      />

      <Menu
        id="dropdown-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={() => handleOpenClick()}>View</MenuItem>
        {editable && (
          <>
            <MenuItem onClick={handleEditClick}>Edit</MenuItem>
            <MenuItem onClick={handleDeleteClick}>Delete</MenuItem>
          </>
        )}
      </Menu>

      <CardMedia component="img" image={car?.images[0]} alt="Car" />

      <CardContent sx={{ padding: "16px" }}>
        <Typography
          variant="h4"
          sx={{
            fontWeight: "600",
            color: "#1a3d6d",
            marginBottom: "4px",
          }}
        >
          {car.name ?? "_"}
        </Typography>
        <Typography
          variant="h4"
          sx={{
            fontWeight: "bold",
            color: "#e53935",
            marginBottom: "6px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <CurrencyRupeeIcon sx={{ fontSize: "20px", marginRight: "4px" }} />
          {car.rate ?? "_"}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: "#757575",
            marginBottom: "6px",
            fontSize: "18px",
          }}
        >
          {car.fuelType ?? "_"} | {car.transmission ?? "_"} | {car.year ?? "_"}{" "}
          | {car.kilometer ?? "_"} km
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            color: "#424242",
            fontSize: "14px",
          }}
        >
          <LocationOnIcon sx={{ fontSize: "20px", marginRight: "6px" }} />
          Ernakulam
        </Box>
      </CardContent>
    </Card>
  );
}
