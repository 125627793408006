import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDCHce-96bHGcWJfgKr1VXGBmVLhLFSnBc",
  authDomain: "wheelzloop-94490.firebaseapp.com",
  projectId: "wheelzloop-94490",
  storageBucket: "wheelzloop-94490.firebasestorage.app",
  messagingSenderId: "895065422973",
  appId: "1:895065422973:web:6a2328880d72b9a64c0514",
  measurementId: "G-DWC93BHT5T",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

export { db, auth };
