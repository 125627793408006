import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import { Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function CarCard2({ car }) {
  const navigate = useNavigate();
  return (
    <Card
      onClick={() => {
        navigate(`/used-cars?brand=${car.name}`);
      }}
      sx={{
        maxWidth: "100%",
        borderRadius: 4,
        boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.1)",
        transition: "transform 0.3s, box-shadow 0.3s",
        "&:hover": {
          transform: "scale(1.03)",
          boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.2)",
        },
      }}
      className="m-2"
    >
      <CardHeader
        action={
          <IconButton aria-label="settings" sx={{ color: "red" }}></IconButton>
        }
      />
      <CardMedia
        component="img"
        image={car?.images[0]}
        alt="Car"
        sx={{
          height: 200,
          maxHeight: 100,
          objectFit: "contain",
          borderRadius: "0px 0px 20px 20px",
        }}
      />
      <CardContent sx={{ padding: "16px" }}>
        <Typography
          variant="h4"
          sx={{
            fontWeight: "600",
            color: "#1a3d6d",
            marginBottom: "4px",
          }}
        >
          {car.name ?? "_"}
        </Typography>
      </CardContent>
    </Card>
  );
}
