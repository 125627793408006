import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import "./Header.css";
import {
  AppBar,
  Toolbar,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Search, AccountCircle } from "@mui/icons-material";
import useMobileView from "../../hooks/isMobile";
import { searchCars } from "../../services/apis";
import CloseIcon from "@mui/icons-material/Close";
import useAuth from "../../hooks/useAuth";

function Header() {
  const [mobileSearch, setMobileSearch] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { isMobile } = useMobileView();
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const { currentUser, username, logout, isLoggedIn } = useAuth();

  const [isMobile2, setIsMobile2] = useState(window.innerWidth <= 768);

  const updateScreenSize = () => {
    setIsMobile2(window.innerWidth <= 768);
  };
  useEffect(() => {
    window.addEventListener("resize", updateScreenSize);
    return () => {
      window.removeEventListener("resize", updateScreenSize);
    };
  }, []);

  const isMenuOpen = Boolean(anchorEl);
  const isMoreOpen = Boolean(anchorEl2);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOpenMore = (e) => {
    setAnchorEl2(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl2(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const toggleMobileSearch = () => {
    setMobileSearch((prev) => !prev);
  };

  const navigateToHome = () => {
    window.location.href = "/";
  };

  const handleSearch = async () => {
    if (searchTerm.trim() === "") {
      setSearchResults([]);
      return;
    }
    try {
      const results = await searchCars(searchTerm);
      setSearchResults(results);
    } catch (error) {
      console.error("Search error:", error);
    }
  };

  const handleLogout = () => {
    Swal.fire({
      title: "Do you want to logout ?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.href = "/signin";
        logout();
      }
    });
  };

  return (
    <AppBar position="fixed" className="header">
      <Toolbar className="toolbar">
        <div className="brand" onClick={navigateToHome}>
          WheelzLoop
        </div>
        <div
          className={`search-container ${
            mobileSearch ? "visible-mobile" : "visible-laptop"
          }`}
          style={{ position: "relative" }}
        >
          <InputBase
            placeholder="Search by name, brand..."
            className="search-input"
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              handleSearch();
            }}
            onKeyPress={(e) => e.key === "Enter" && handleSearch()}
          />
          <IconButton onClick={handleSearch}>
            {searchTerm.trim() != "" ? (
              <CloseIcon
                className="search-icon"
                style={{ fontSize: "25px" }}
                onClick={() => setSearchTerm("")}
              />
            ) : (
              <Search className="search-icon" style={{ fontSize: "25px" }} />
            )}
          </IconButton>

          <div className="search-results">
            {searchResults.length == 0 && searchTerm.trim() != "" ? (
              <div className="search-item">
                <a style={{ color: "#111" }}>No Item found</a>
              </div>
            ) : (
              searchResults.length > 0 &&
              searchTerm.trim() != "" && (
                <>
                  {searchResults.map((car) => (
                    <div key={car.id} className="search-item">
                      <a href={`/car/${car.id}`}>
                        <img
                          src={car?.images[0]}
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "50%",
                          }}
                        />
                        {car.name} - {car.brand}
                      </a>
                    </div>
                  ))}
                </>
              )
            )}
          </div>
        </div>
        <div className={`nav-options ${mobileSearch ? "visible-mobile" : ""}`}>
          <a href="/">Home</a>
          <a href="/used-cars">Explore-cars</a>
          {/* <a href="/chat">Chat</a> */}
          <a href="/reviews">Reviews</a>
          <a href="/blogs">Blogs</a>
          <a href="/saved-cars">Saved-Cars</a>
          <a style={{ color: "#111" }} onClick={handleOpenMore}>
            More
            <ArrowDropDownIcon style={{ fontSize: "20px" }} />
          </a>
        </div>
        {isLoggedIn ? (
          <>
            <IconButton
              edge="end"
              onClick={handleProfileMenuOpen}
              className="profile-icon"
            >
              <span className="font-md">{username}</span>
              <AccountCircle style={{ fontSize: "25px" }} />
            </IconButton>
          </>
        ) : (
          <>
            <button
              className="medium"
              style={{ width: "100px" }}
              onClick={() => (window.location.href = "/signin")}
            >
              Login
            </button>
          </>
        )}

        <Menu
          id="more-menu"
          anchorEl={anchorEl2}
          keepMounted
          open={isMoreOpen}
          onClose={handleCloseMenu}
        >
          <MenuItem
            onClick={() => (window.location.href = "/about-us")}
            className="font-md"
            style={{ fontSize: "16px" }}
          >
            About Us
          </MenuItem>
          <MenuItem
            onClick={() => {
              window.location.href = "/contact-us";
            }}
            style={{ fontSize: "16px" }}
          >
            Contact Us
          </MenuItem>
        </Menu>
        {isLoggedIn && (
          <>
            <Menu
              id="profile-menu"
              anchorEl={anchorEl}
              keepMounted
              open={isMenuOpen}
              onClose={handleMenuClose}
            >
              <MenuItem
                onClick={() => {
                  window.location.href = "/profile";
                }}
                className="font-md"
                style={{ fontSize: "16px" }}
              >
                Profile
              </MenuItem>
              <MenuItem onClick={handleLogout} style={{ fontSize: "16px" }}>
                Logout
              </MenuItem>
            </Menu>
          </>
        )}

        {isMobile && (
          <IconButton
            className="mobile-menu-button"
            onClick={toggleMobileSearch}
          >
            <Search />
          </IconButton>
        )}
      </Toolbar>
      {isMobile2 && (
        <>
          <div style={{ color: "red", backgroundColor: "#ececec" }}>
            <ul class="nav">
              <li class="nav-item">
                <a
                  class="nav-link"
                  href="/used-cars"
                  style={{ color: "#111", fontFamily: "sans-serif" }}
                >
                  Explore-cars
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  href="/reviews"
                  style={{ color: "#111", fontFamily: "sans-serif" }}
                >
                  Reviews
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  href="/saved-cars"
                  style={{ color: "#111", fontFamily: "sans-serif" }}
                >
                  Favourites
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link active"
                  href="/blogs"
                  style={{ color: "#111", fontFamily: "sans-serif" }}
                >
                  Blogs
                </a>
              </li>
            </ul>
          </div>
        </>
      )}
    </AppBar>
  );
}

export default Header;
