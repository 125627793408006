import React from "react";
import "./AboutUsScreen.css";
import YouTube from "react-youtube";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
} from "react-icons/fa";

function AboutUsScreen() {
  const opts = {
    height: "390",
    width: "100%",
    playerVars: {
      autoplay: 1,
    },
  };
  const ready = (event) => {
    event.target.pauseVideo();
  };
  return (
    <div className="mt-2">
      <div className="about-us-screen">
        <section className="about-us-section">
          <div className="about-us-content">
            <h1 className="about-us-title">Welcome to WheelzLoop!</h1>
            <p className="about-us-subtitle">
              Your Trusted Marketplace for Buying and Selling Used Cars
            </p>
            <p className="about-us-description">
              At WheelzLoop, we believe buying and selling used cars should be
              easy, transparent, and secure. Whether you’re a buyer looking for
              a trusted deal or a seller seeking a reliable platform, WheelzLoop
              is here to make the process as smooth as possible. We're a
              community-driven platform designed for people who value
              reliability and trust when it comes to used cars.
            </p>
            <YouTube videoId="efSw2iVRr4w" opts={opts} onReady={ready} />
            <div className="about-us-highlight">
              <h2>Why Choose WheelzLoop?</h2>
              <p>
                At WheelzLoop, we’re dedicated to bringing buyers and sellers
                together in a safe and efficient environment. Here’s why you
                should join us:
              </p>
            </div>
          </div>

          <div className="about-us-advantages">
            <div className="advantage-item">
              <div className="advantage-icon">🔒</div>
              <h3 className="advantage-title">
                Secure and Trusted Transactions
              </h3>
              <p className="advantage-description">
                We take the security of your personal information and
                transactions seriously. Our platform ensures that every deal is
                safe and transparent, giving you peace of mind.
              </p>
            </div>
            <div className="advantage-item">
              <div className="advantage-icon">🚗</div>
              <h3 className="advantage-title">Wide Range of Cars</h3>
              <p className="advantage-description">
                Whether you're looking for a budget-friendly option or a luxury
                vehicle, WheelzLoop offers a vast selection of used cars to suit
                your needs and preferences.
              </p>
            </div>
            <div className="advantage-item">
              <div className="advantage-icon">💬</div>
              <h3 className="advantage-title">Direct Dealer Communication</h3>
              <p className="advantage-description">
                Connect directly with trusted dealers through our secure chat
                system, making negotiations smoother and more convenient.
              </p>
            </div>
            <div className="advantage-item">
              <div className="advantage-icon">🛠️</div>
              <h3 className="advantage-title">Easy Car Listing</h3>
              <p className="advantage-description">
                Sellers can easily list their cars with clear, detailed
                descriptions and photos, making it easier for potential buyers
                to make an informed decision.
              </p>
            </div>
          </div>

          <div className="about-us-footer">
            <p className="footer-text">
              We are just getting started, and we’re excited to bring you a
              platform that simplifies the used car market. Join WheelzLoop
              today and experience the future of buying and selling used cars.
            </p>
          </div>

          <div className="social-media">
            <h3>Follow Us on Social Media</h3>
            <div className="social-icons">
              <a
                href="https://www.facebook.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebookF />
              </a>
              <a
                href="https://www.twitter.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaTwitter />
              </a>
              <a
                href="https://www.instagram.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram />
              </a>
              <a
                href="https://www.linkedin.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedinIn />
              </a>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default AboutUsScreen;
