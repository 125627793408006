import React from "react";
import "./Card.css";
import EmptyScreen from "../../screens/EmptyScreen/EmptyScreen";
import { NO_IMAGE } from "../../constants/urls";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { useNavigate } from "react-router-dom";

function Card({ car }) {
  const navigate = useNavigate();

  const handleNavigateDetailsPage = (id) => {
    navigate(`/details/${id}`);
  };

  return (
    <div>
      {!car ? (
        <EmptyScreen />
      ) : (
        <>
          <div className="card">
            <div className="badge">New</div>
            <div
              className="image-container m-2"
              onClick={() => handleNavigateDetailsPage(car.id)}
            >
              {car.images.length === 0 ? (
                <img src={NO_IMAGE} className="medium" loading="lazy" />
              ) : (
                <img src={car.images[0]} className="medium" loading="lazy" />
              )}
            </div>
            <div className="details">
              <h6 className="font-sm">
                <div className="car-name">{car.name ?? "_"}</div>
              </h6>
              <h4 className="car-price">
                <CurrencyRupeeIcon />
                {car.rate}
              </h4>
              <p
                className="font-text-grey line-h-md"
                style={{ fontSize: "17px" }}
              >
                {`${car.year} | ${car.fuelType} | ${car.transmission}`}
              </p>
              <p className="line-h-md">
                <LocationOnIcon className="mt-0" style={{ fontSize: "20px" }} />
                {car.location ? car.location : car.place ? car.place : "_"}
              </p>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Card;
