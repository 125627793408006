export const brands = [
  {
    brand: "Maruti Suzuki",
    cars: [
      "Alto",
      "Swift",
      "WagonR",
      "Celerio",
      "Ignis",
      "Baleno",
      "S-Presso",
      "Dzire",
      "Ciaz",
      "Vitara Brezza",
      "S-Cross",
    ],
  },
  {
    brand: "Tata",
    cars: [
      "Nano",
      "Tiago",
      "Tigor",
      "Altroz",
      "Harrier",
      "Nexon",
      "Safari",
      "Hexa",
      "Zest",
      "Bolt",
    ],
  },
  {
    brand: "Hyundai",
    cars: [
      "i10",
      "i20",
      "Verna",
      "Creta",
      "Venue",
      "Elantra",
      "Santro",
      "Kona Electric",
      "Tucson",
      "Grand i10",
    ],
  },
  {
    brand: "Renault",
    cars: ["Kwid", "Triber", "Duster", "Captur", "Lodgy", "Talisman"],
  },
  {
    brand: "Volkswagen",
    cars: ["Polo", "Vento", "Tiguan", "Virtus", "Jetta", "Taigun"],
  },
  {
    brand: "Nissan",
    cars: ["Micra", "Sunny", "Magnite", "Terrano", "Kicks"],
  },
  {
    brand: "Datsun",
    cars: ["Go", "Go+", "Redi-Go"],
  },
  {
    brand: "Ford",
    cars: ["Figo", "EcoSport", "Endeavour", "Aspire", "Freestyle", "Mustang"],
  },
  {
    brand: "Honda",
    cars: ["Amaze", "City", "Jazz", "WR-V", "Civic", "CR-V", "Brio"],
  },
  {
    brand: "MG Motors",
    cars: ["Hector", "Astor", "ZS EV", "Gloster", "EZS"],
  },
  {
    brand: "Skoda",
    cars: ["Octavia", "Superb", "Rapid", "Kushaq", "Slavia"],
  },
  {
    brand: "Mahindra",
    cars: [
      "XUV300",
      "XUV500",
      "Thar",
      "Scorpio",
      "Bolero",
      "Marazzo",
      "Alturas G4",
      "e2o Plus",
    ],
  },
  {
    brand: "Kia",
    cars: ["Seltos", "Sonet", "Carnival", "EV6", "Sportage"],
  },
  {
    brand: "Chevrolet",
    cars: ["Beat", "Spark", "Cruze", "Aveo", "Tavera", "Trax"],
  },
  {
    brand: "Audi",
    cars: ["A3", "A4", "A6", "A8", "Q3", "Q5", "Q7", "Q8", "RS5", "RS7", "R8"],
  },
  {
    brand: "BMW",
    cars: [
      "1 Series",
      "3 Series",
      "5 Series",
      "7 Series",
      "X1",
      "X3",
      "X5",
      "X7",
      "Z4",
      "M5",
    ],
  },
  {
    brand: "Citroen",
    cars: ["C3", "C5 Aircross", "C4", "C5", "Berlingo"],
  },
  {
    brand: "Ferrari",
    cars: ["488 GTB", "F8 Tributo", "Portofino", "Roma", "812 Superfast"],
  },
  {
    brand: "Fiat",
    cars: ["Punto", "Linea", "500", "Panda", "Tipo"],
  },
  {
    brand: "Jaguar",
    cars: ["XE", "XF", "XJ", "F-Pace", "E-Pace", "I-Pace"],
  },
  {
    brand: "Jeep",
    cars: ["Compass", "Wrangler", "Grand Cherokee", "Renegade"],
  },
  {
    brand: "Lamborghini",
    cars: ["Aventador", "Huracán", "Urus", "Sián", "Gallardo"],
  },
  {
    brand: "Land Rover",
    cars: ["Defender", "Discovery", "Range Rover", "Evoque", "Sport"],
  },
  {
    brand: "Lexus",
    cars: ["RX", "NX", "LS", "IS", "LC", "LX"],
  },
  {
    brand: "Maserati",
    cars: ["Ghibli", "Levante", "Quattroporte", "MC20"],
  },
  {
    brand: "Maybach",
    cars: ["S-Class Maybach", "GLS Maybach"],
  },
  {
    brand: "Mercedes-Benz",
    cars: [
      "A-Class",
      "C-Class",
      "E-Class",
      "S-Class",
      "GLA",
      "GLB",
      "GLC",
      "GLE",
      "GLS",
      "EQC",
      "AMG GT",
    ],
  },
  {
    brand: "Mini",
    cars: ["Mini Cooper", "Countryman", "Clubman", "Convertible"],
  },
  {
    brand: "Mitsubishi",
    cars: ["Pajero", "Outlander", "Eclipse Cross", "Lancer", "Montero"],
  },
  {
    brand: "Porsche",
    cars: ["911", "Cayenne", "Macan", "Taycan", "Panamera"],
  },
  {
    brand: "Rolls-Royce",
    cars: ["Phantom", "Ghost", "Wraith", "Dawn", "Cullinan"],
  },
  {
    brand: "Tata EV",
    cars: ["Tata Nexon EV", "Tata Tigor EV", "Tata Altroz EV"],
  },
  {
    brand: "Toyota",
    cars: [
      "Innova Crysta",
      "Fortuner",
      "Camry",
      "Corolla Altis",
      "Yaris",
      "Glanza",
      "Vellfire",
      "Land Cruiser",
      "Hilux",
    ],
  },
  {
    brand: "Volvo",
    cars: ["XC40", "XC60", "XC90", "S60", "S90", "V90", "V60"],
  },
];
