import React, { useState } from "react";
import "./ContactScreen.css";
import { FaInstagram, FaLinkedinIn } from "react-icons/fa";
import EmailIcon from "@mui/icons-material/Email";
import FacebookIcon from "@mui/icons-material/Facebook";
import XIcon from "@mui/icons-material/X";

import { BACKEND_URL } from "../../constants/urls";
import Swal from "sweetalert2";
import axios from "axios";

function ContactScreen() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [feedback, setFeedback] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const validateForm = () => {
    const newErrors = {};
    if (!name) newErrors.name = "Name is required";
    if (!email) newErrors.email = "Email is required";
    if (!feedback) newErrors.feedback = "Feedback is required";
    if (feedback.length < 2 || feedback.length > 200)
      newErrors.feedback = "Feedback must be between 2 and 200 characters";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      await axios.post(`${BACKEND_URL}/api/v1/customer/feedback`, {
        name,
        email,
        feedback,
      });
      setLoading(false);
      Swal.fire({
        title: "Thank YOU",
        text: "Thank you for your feedback!",
        icon: "success",
      });
      setName("");
      setEmail("");
      setFeedback("");
    }
  };

  return (
    <div className="contact-screen saved-cars">
      <h1 className="contact-title">Contact Us</h1>
      <div className="contact-container">
        <div className="contact-info">
          <h2>Get in Touch</h2>
          <p>
            <EmailIcon /> wheelzloop@gmail.com
          </p>
          <h3>Follow Us</h3>
          <div className="social-icons">
            <a
              href="https://www.facebook.com/profile.php?id=61565890412918"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FacebookIcon style={{ color: "blue", fontSize: "20px" }} />
            </a>
            <a
              href="https://x.com/WheelzLoop"
              target="_blank"
              rel="noopener noreferrer"
            >
              <XIcon style={{ color: "black", fontSize: "20px" }} />
            </a>
            <a
              href="https://www.instagram.com/wheelz_loop/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram style={{ color: "purple", fontSize: "20px" }} />
            </a>
            <a
              href="https://www.linkedin.com/in/wheelzloop-used-car-selling-platform-baa71b352/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedinIn style={{ color: "blue", fontSize: "20px" }} />
            </a>
          </div>
          <p>
            We are here to help you with any questions or concerns you may have.
            Feel free to reach out to us via email or social media. Your
            feedback is valuable to us!
          </p>
        </div>
        <div className="feedback-form">
          <h2>Feedback</h2>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder="Full Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            {errors.name && <p className="error">{errors.name}</p>}
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            {errors.email && <p className="error">{errors.email}</p>}
            <textarea
              placeholder="Your Message"
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
              rows="5"
              required
            />
            {errors.feedback && <p className="error">{errors.feedback}</p>}
            <button type="submit" disabled={loading} aria-label="Search for used cars">
              Send Feedback
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ContactScreen;
