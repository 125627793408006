import React from "react";
import { useLocation, Link } from "react-router-dom";
import "./Breadcrumb.css";

const breadcrumbMap = {
  details: "View Car",
};

const Breadcrumb = () => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  return (
    <nav className="breadcrumb">
      <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
        {pathnames.map((value, index) => {
          const isLast = index === pathnames.length - 1;
          const isDynamic = index > 0 && !breadcrumbMap[value];
          const fullPath = pathnames.slice(0, index + 1).join("/");
          const breadcrumbLabel =
            breadcrumbMap[value] || (isDynamic ? "View Car" : value);

          return (
            <li key={fullPath}>
              {isLast ? (
                <span>{breadcrumbLabel}</span>
              ) : (
                <Link to={`/${fullPath}`}>{breadcrumbLabel}</Link>
              )}
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default Breadcrumb;
