import React from "react";
import "./HomeScreen.css";
import Banner from "../../Components/Banner/Banner";
import LatestCars from "../LatestCars/LatestCars";
import Counter from "../../Components/Counter/Counter";
import NewsAndResources from "../NewsAndResources/NewsAndResources";
import CategoryCars from "../CategoryCars/CategoryCars";
import AdvertisementScreens from "../AdvertisementScreens/AdvertisementScreens";
import HowItWorks from "../../sections/HowItWorks/HowItWorks";
import FAQ from "../../sections/FAQ/FAQ";

function HomeScreen() {
  return (
    <div className="grid-container">
      <Banner />
      <div>
        <LatestCars />
      </div>
      <CategoryCars />
      <div className="">
        <AdvertisementScreens />
      </div>
      <div className="mt-0">
        <HowItWorks />
      </div>
      <div className="mt-4">
        <Counter />
      </div>
      <NewsAndResources />
      <div className="mt-4">
        <FAQ />
      </div>
    </div>
  );
}

export default HomeScreen;
