import React from "react";
import "./ReviewScreen.css";
import Rating from "../../Components/Rating/Rating";

function ReviewScreen() {
  return (
    <section className="review-page">
      <div className="container p-5">
        <h2>Our Reviews</h2>
        <p className="description mt-3">
          At WheelzLoop, we take pride in offering an exceptional car buying
          experience. Don’t just take our word for it—hear what our customers
          have to say! From seamless transactions to trusted service, our
          reviews reflect the quality and reliability we stand for. Read on to
          discover why our customers choose WheelzLoop for their next car
          purchase.
        </p>
        <div className="rating-section mt-5">
          <div className="rating-header">
            <h3 className="rating-large">4.2 / 5</h3>
            <Rating rating={4.5} />
          </div>
          <div className="row mt-4">
            <div className="col-md-6">
              <div className="bar-graph">
                {[
                  { label: "5 stars", value: 100, count: 6 },
                  { label: "4 stars", value: 80, count: 4 },
                  { label: "3 stars", value: 60, count: 3 },
                  { label: "2 stars", value: 30, count: 2 },
                ].map((item, index) => (
                  <div className="graph" key={index}>
                    <p className="rating-label">{item.label}</p>
                    <div className="bar-container">
                      <div
                        className="bar"
                        style={{
                          width: `${item.value}%`,
                          backgroundColor: "#21cb98",
                        }}
                      ></div>
                    </div>
                    <h6 className="bar-count">{item.count} Votes</h6>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-md-6">
              <div className="review-summary">
                <h4>Customer Satisfaction</h4>
                <p>
                  Most of our customers love the smooth buying experience and
                  excellent service. Check out individual ratings to see how we
                  score across different categories.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="customer-reviews mt-5">
          <h3>What Our Customers Say</h3>
          <div className="row">
            {[
              {
                name: "Vishnu",
                rating: 5,
                review:
                  "Amazing experience! The process was smooth, and I got my dream car at a great price.",
              },
              {
                name: "Avinash",
                rating: 4,
                review:
                  "Very professional team. They helped me find the perfect car for my family.",
              },
              {
                name: "Aswin",
                rating: 5,
                review:
                  "Highly recommend WheelzLoop. Their service is top-notch!",
              },
            ].map((review, index) => (
              <div className="col-md-4" key={index}>
                <div className="review-card">
                  <h5>{review.name}</h5>
                  <Rating rating={review.rating} />
                  <p className="review-text">{review.review}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default ReviewScreen;
