import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { getProfileById } from "../../services/apis";
import PhoneIcon from "@mui/icons-material/Phone";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { IconButton, Tooltip } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  textAlign: "left",
};

export default function BasicModal({ userId }) {
  const [open, setOpen] = React.useState(false);
  const [dealer, setDealer] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  React.useEffect(() => {
    const fetchProfile = async () => {
      if (userId) {
        setLoading(true);
        const res = await getProfileById(userId);
        setLoading(false);
        if (res) {
          setDealer(res);
        }
      }
    };
    fetchProfile();
  }, [userId]);

  const handleCall = (phoneNumber) => {
    window.location.href = `tel:${phoneNumber}`;
  };

  const handleCopy = (phoneNumber) => {
    navigator.clipboard.writeText(phoneNumber);
  };

  return (
    <div>
      <button
        onClick={handleOpen}
        aria-label="Search for used cars"
        className="chat-button mb-2 w-100"
      >
        Get Dealer Details
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {dealer && (
            <>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                style={{ fontSize: "20px" }}
              >
                {dealer.fullName}
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ mt: 2 }}
                style={{ fontSize: "16px" }}
              >
                {dealer.email}
              </Typography>
              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{ mr: 2 }}
                  style={{ fontSize: "16px" }}
                >
                  {dealer.phoneNumber}
                </Typography>
                <Tooltip title="Call">
                  <IconButton
                    onClick={() => handleCall(dealer.phoneNumber)}
                    style={{
                      backgroundColor: "green",
                      width: "40px",
                      height: "40px",
                      borderRadius: "50%",
                      color: "white",
                    }}
                  >
                    <PhoneIcon style={{ fontSize: "20px" }} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Copy">
                  <IconButton onClick={() => handleCopy(dealer.phoneNumber)}>
                    <ContentCopyIcon style={{ fontSize: "20px" }} />
                  </IconButton>
                </Tooltip>
              </Box>
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
}
