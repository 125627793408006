export const fuelTypes = [
  {
    key: 1,
    type: "petrol",
    text: "Petrol",
  },
  {
    key: 2,
    type: "diesel",
    text: "Diesel",
  },
  {
    key: 3,
    type: "cng",
    text: "CNG",
  },
  {
    key: 4,
    type: "electric",
    text: "Electric",
  },
];
