export const ownerShip = [
  {
    key: 1,
    text: "1st Owner",
    value: 1,
  },
  {
    key: 2,
    text: "2nd Owner",
    value: 2,
  },
  {
    key: 3,
    text: "3rd Owner",
    value: 3,
  },
  {
    key: 4,
    text: "Above 3",
    value: 4,
  },
];
